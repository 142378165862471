<template>
  <v-list-group
    v-if="node.create && hasAnyVisibleChild"
    no-action
    eager
    :value="active"
    :prepend-icon="node.icon"
    append-icon="ri-arrow-drop-down-fill"
  >
    <template v-slot:activator>
      <v-list-item-title>{{ node.label }}</v-list-item-title>
    </template>

    <childLink
      @activeChange="activeChange"
      v-for="(child, childIndex) in children"
      :is-child="true"
      :node="child"
      :key="childIndex"
    >
    </childLink>
  </v-list-group>
</template>

<script lang="babel" type="text/babel">
import node from './node.js'
export default {
  mixins: [node],
  data() {
    return {
      active: false,
    }
  },
  methods: {
    activeChange(status) {
      this.active = status
    },
  },
  computed: {
    children() {
      if(!Array.isArray(this.node.group)) return
      return this.node.group
    },
     hasAnyVisibleChild() {
      return this.children.some(node => node.create === true)
    },
  },
  components: {
    childLink: () => import('./link.vue')
  },
}
</script>

<style lang="sass" type="text/sass">
.provider-store-layout
  .v-list-group__header
    background: #EBF5FF
    .v-list-item__title,.v-list-item__icon>.v-icon
      color: #006DE0 !important
  .v-list-group__items
    background: #ffffff

.linerp-layout
  .v-list-group__header
    background: #fff3e4
    .v-list-item__title,.v-list-item__icon>.v-icon
      color: #2B0D00 !important
  .v-list-group__items
    background: #ffffff
</style>